import React from 'react';
import './BotAvtar.css';


const BotAvtar = () => {
  return (
    <div className='maxx-bot-avtar'></div>
  )
}

export default BotAvtar